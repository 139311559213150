import React, { Component } from 'react'
import { HeaderBar } from 'components/HeaderBar'

import { ProfileMenu } from 'components/ProfileMenu'

export class Header extends Component {
  render() {
    return (
      <HeaderBar
        onMenuToggle={this.props.onMenuToggle}
        profileMenu={<ProfileMenu />}
      />
    )
  }
}

export const SubHeaderBar = ({ children }) => {
  return <section className="subheader">{children}</section>
}
