import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { default as DayPicker, DateUtils } from 'react-day-picker'

import { Button } from 'samespace-ui-kit'
import { Input } from '../Input'

import moment from 'moment'

import 'react-day-picker/lib/style.css'
import './styles.scss'

export class Calendar extends Component {
  constructor(props) {
    super(props)
    this.handleDayClick = this.handleDayClick.bind(this)
    this.handleResetClick = this.handleResetClick.bind(this)
    this.handleOnSave = this.handleOnApply.bind(this)
    this.state = this.getInitialState(props)
  }

  getInitialState(props = false) {
    if (!props) {
      return {
        from: undefined,
        to: undefined,
        errorText: '',
        startTime: '00:00:00 AM',
        endTime: '00:00:00 AM',
      }
    }

    const {
      allowFutureDates,
      includeTime,
      fromMonth,
      toMonth,
      selectedFrom,
      selectedTo,
    } = props

    console.log('getInitialState -> ', props)
    let fromDate = selectedFrom
    let toDate = selectedTo

    let fromTime = '00:00:00 AM'
    let toTime = '00:00:00 AM'
    let initialMonth = undefined

    if (
      selectedFrom &&
      selectedTo &&
      selectedFrom.getTime() > selectedTo.getTime()
    )
      toDate = undefined

    if (selectedFrom) {
      if (selectedFrom.getTime() > new Date().getTime()) {
        if (!allowFutureDates) fromDate = undefined
      }

      if (
        (fromMonth && selectedFrom.getTime() < fromMonth.getTime()) ||
        (toMonth && selectedFrom.getTime() > toMonth.getTime())
      )
        fromDate = undefined

      if (fromDate) {
        initialMonth = fromDate
        if (includeTime) fromTime = moment(selectedFrom).format('HH:mm:ss A')
      }
    }

    if (selectedTo) {
      if (selectedTo.getTime() > new Date().getTime()) {
        if (!allowFutureDates) toDate = undefined
      }

      if (
        (fromMonth && selectedTo.getTime() < fromMonth.getTime()) ||
        (toMonth && selectedTo.getTime() > toMonth.getTime())
      )
        toDate = undefined

      if (toDate && includeTime)
        toTime = moment(selectedTo).format('HH:mm:ss A')
    }

    if (!fromDate && toDate) {
      toDate = undefined
    }

    const finalState = {
      from: fromDate,
      to: toDate,
      initialMonth,
      errorText: '',
      startTime: fromTime,
      endTime: toTime,
    }
    // console.log('final state -> ', finalState)
    return finalState
  }

  handleDayClick(day) {
    this.setState({
      errorText: '',
    })
    this.props.onDayClicked && this.props.onDayClicked(day)
    const range = DateUtils.addDayToRange(day, this.state)
    this.setState(range)
  }

  handleResetClick() {
    this.props.onResetClicked && this.props.onResetClicked()
    this.setState(this.getInitialState(false))
  }

  handleOnApply() {
    const timerange = {
      from: this.state.from,
      to: this.state.to,
    }
    if (!timerange.from && !timerange.to) {
      this.setState({
        errorText: 'Please select start and end date.',
      })
      return
    }

    if (!timerange.to) {
      this.setState({
        errorText: 'Please select end date.',
      })
      return
    }

    if (this.props.includeTime) {
      const { startTime, endTime } = this.state
      let fromTime = moment(startTime, 'HH:mm:ss a')
      let toTime = moment(endTime, 'HH:mm:ss a')
      timerange.from.setHours(
        fromTime.hour(),
        fromTime.minute(),
        fromTime.seconds(),
        0
      )
      timerange.to.setHours(toTime.hour(), toTime.minute(), toTime.seconds(), 0)

      if (timerange.from.getTime() === timerange.to.getTime()) {
        this.setState({
          errorText: 'Start time and end time cannot be same.',
        })
        return
      }

      if (timerange.from > timerange.to) {
        this.setState({
          errorText: 'Start time cannot be more than end time.',
        })
        return
      }
      if (!this.props.allowFutureDates) {
        if (timerange.from.getTime() > new Date().getTime()) {
          this.setState({
            errorText: 'Start time cannot be in future.',
          })
          return
        }

        if (timerange.to.getTime() > new Date().getTime()) {
          this.setState({
            errorText: 'End time cannot be in future.',
          })
          return
        }
      }
    } else {
      timerange.from.setHours(0, 0, 0, 0)
      timerange.to.setHours(0, 0, 0, 0)
    }

    this.props.onApply && this.props.onApply(timerange)
  }

  render() {
    const { from, to, errorText, startTime, endTime, initialMonth } = this.state
    const modifiers = { start: from, end: to }

    const {
      numberOfMonths = 2,
      primaryButtonText,
      fromMonth,
      toMonth,
      allowFutureDates,
      includeTime,
    } = this.props

    return (
      <div className="calendar-wrapper">
        <DayPicker
          className="calendar"
          numberOfMonths={numberOfMonths}
          selectedDays={[from, { from, to }]}
          modifiers={modifiers}
          onDayClick={this.handleDayClick}
          fromMonth={fromMonth}
          toMonth={!allowFutureDates ? new Date() : toMonth}
          month={initialMonth}
          disabledDays={
            allowFutureDates
              ? undefined
              : {
                  from: new Date(moment().add(1, 'day').valueOf()),
                  to: new Date(moment().add(1, 'day').endOf('month').valueOf()),
                }
          }
        />

        {includeTime && (
          <div className="time-selection-container">
            <Input
              time
              label="Start Time"
              value={startTime}
              onChange={(e) => {
                this.setState({
                  errorText: '',
                  startTime: e.target.value,
                })
              }}
            />
            <Input
              time
              label="End Time"
              value={endTime}
              onChange={(e) => {
                console.log(e)
                this.setState({
                  errorText: '',
                  endTime: e.target.value,
                })
              }}
            />
          </div>
        )}

        <div className="bottom-bar">
          <span className="span-error">{errorText}</span>

          <div className="buttons-container">
            <Button
              rounded
              ghost
              accent="base"
              onClick={(e) => this.handleResetClick()}
            >
              Reset
            </Button>

            <Button
              // disabled={(!from || !to)}
              rounded
              onClick={(e) => this.handleOnApply()}
            >
              {primaryButtonText || 'Apply'}
            </Button>
          </div>
        </div>
      </div>
    )
  }
}

Calendar.propTypes = {
  /** Number of months to render in calendar. */
  numberOfMonths: PropTypes.number,
  /** Text for primary button. */
  primaryButtonText: PropTypes.string,
  /** Start month of calendar. */
  fromMonth: PropTypes.string,
  /** End month of calendar. */
  toMonth: PropTypes.string,
  /** Future dates can be selected if allowFutureDates value is true.  */
  allowFutureDates: PropTypes.bool,
  /** Time selector is included to choose time if includeTime value is true. */
  includeTime: PropTypes.bool,
}

Calendar.defaultProps = {
  numberOfMonths: 2,
  primaryButtonText: 'Apply',
  allowFutureDates: false,
  includeTime: false,
}
