import React, { useState, useEffect, useRef } from 'react'
import classnames from 'classnames'
import { Button, Avatar } from 'samespace-ui-kit'

import { useSession } from 'context/session'
import { clearSession } from 'utils/session'
import { USER_IMAGES } from 'utils/dummy'

import './styles.scss'

export function ProfileMenu() {
  const dropRef = useRef(null)
  const [open, setOpen] = useState(false)
  const [session] = useSession()
  const { user } = session

  useEffect(() => {
    const handleMouseUp = (event) => {
      if (open && dropRef.current && !dropRef.current.contains(event.target)) {
        setOpen(false)
      }
    }
    document.addEventListener('mouseup', handleMouseUp)
    return () => {
      document.removeEventListener('mouseup', handleMouseUp)
    }
  })

  return (
    <article ref={dropRef} className="profile-menu">
      <article className="profile-menu__toggle">
        <Button clear rounded onClick={() => setOpen(!open)}>
          <Avatar
            size="s"
            title={`${user.name.charAt(0)}`}
            accent="blue"
            light
          />
        </Button>
      </article>
      <article
        className={classnames('profile-drop', {
          open: open,
        })}
      >
        <section className="profile-drop__section">
          <div className="profile-self-info">
            <Avatar
              size="xl"
              title={`${user.name.charAt(0)}`}
              accent="blue"
              light
            />
            <p>{user.name}</p>
            <h6>{user.email_address}</h6>
            <h6 className="text--primary">{user.type}</h6>
          </div>
        </section>
        <section className="profile-drop__section">
          <div className="profile-logout-action">
            <Button accent="base" ghost rounded onClick={() => clearSession()}>
              Sign out
            </Button>
          </div>
        </section>
      </article>
    </article>
  )
}
