import React, { useEffect, useState } from 'react'
import BoatImg from 'assets/img/boat.png'
import { saveSession } from 'utils/session'

import { Loader } from 'samespace-ui-kit'
import jwt_decode from 'jwt-decode'

export default function SSOLogin(props) {
  const [error, setError] = useState(false)
  useEffect(() => {
    let params = new URLSearchParams(window.location.search)
    let payload = params.get('payload')
    if (payload !== null) {
      const data = JSON.parse(atob(payload))
      const jwtData = atob(payload)
      const decodedData = jwt_decode(jwtData)
      const authenticationData = {
        data: { ...decodedData.user },
        token: data.data.token,
      }
      saveSession(authenticationData, decodedData.exp)
    } else {
      setError(true)
    }
  }, [])
  return (
    <>
      <Loader />
      {error && (
        <article className="page-error">
          <section>
            <img src={BoatImg} alt="Sinking Boat" />
            <h4>We’re sorry</h4>
            <p>
              Something went wrong with your request. Please try again later or
              contact us.
            </p>
          </section>
        </article>
      )}
    </>
  )
}
