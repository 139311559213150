import React, { createContext, useState, useContext, useMemo } from 'react'

export const initialDatasets = {
  products: [],
  sub_products: [],
  countries: [],
  currencies: [],
  destinations: [],
  companies: [],
  carriers: [],
  account_managers: [],
  voice_routes: [],
  sm_users: [],
  customers: [],
  customers_transaction_category: [],
}

const DatasetContext = createContext()

export function useDatasets() {
  const context = useContext(DatasetContext)
  if (!context) {
    throw new Error(`useDatasets must be used within a DatasetProvider`)
  }
  return context
}

export function DatasetProvider(props) {
  const [datasets, setDatasets] = useState(initialDatasets)
  const value = useMemo(() => [datasets, setDatasets], [datasets])
  return <DatasetContext.Provider value={value} {...props} />
}
