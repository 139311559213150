import axios from 'axios'
import { clearSession } from 'utils/session'
import { APIS } from 'utils/urls'

export const DATASETS = {
  PRODUCTS: 'products',
  SUB_PRODUCTS: 'sub_products',
  COUNTRIES: 'countries',
  CURRENCIES: 'currencies',
  DESTINATIONS: 'destinations',
  COMPANIES: 'companies',
  CARRIERS: 'carriers',
  MANAGERS: 'account_managers',
  ROUTES: 'voice_routes',
  SM_USERS: 'sm_users',
  CUSTOMERS: 'customers',
  TRANSACTION_CATEGORIES: 'customers_transaction_category',
}

export async function fetchDatasets(filters) {
  const datasets = filters || Object.values(DATASETS)
  const params = { filter: datasets }
  const queryString = new URLSearchParams(params).toString()
  const url = `${APIS.DATASETS}?${queryString}`
  try {
    const response = await axios.get(url)
    return response.data.data
  } catch (e) {
    if (e.response?.status === 401) {
      clearSession()
    }
    return null
  }
}
