import axios from 'axios'
import { toast } from 'samespace-ui-kit'

import { API_BASE, APIS } from './urls'
import { getCookie, setCookie, removeCookie } from './cookies'
import jwt_decode from 'jwt-decode'

export function getSavedSession() {
  const accessToken = getCookie('accessToken')
  const userInfo = getCookie('userInfo')
  if (accessToken && userInfo) {
    return {
      accessToken,
      userInfo,
    }
  } else {
    return null
  }
}

export function checkManager() {
  const userInfo = getCookie('userInfo')
  if (userInfo?.account_manger_id) {
    return false
  } else return true
}
export function getPrivileges() {
  const accessToken = getCookie('accessToken')
  const userInfo = getCookie('userInfo')
  if (accessToken && userInfo) {
    return {
      ...userInfo.privileges,
    }
  } else {
    return null
  }
}
export async function handleLogin(payload) {
  const url = `${
    process.env.REACT_APP_HUB_BASE_URL || 'https://api.ss.dev'
  }/auth/api/login`
  try {
    const response = await axios.post(url, { ...payload, remember: true })
    const decoded = jwt_decode(response.data.token)
    saveSession(response.data, decoded.ext)
  } catch (err) {
    const msg = err?.response?.data?.errors[0]?.message
    toast.error(msg || 'Something went wrong!')
  }
}

export function saveSession(data, expiry) {
  setCookie(`userInfo`, data.data, expiry)
  setCookie(`accessToken`, data.token, expiry)
  window.location.href = '/accounts'
}

export function clearSession() {
  removeCookie(`userInfo`)
  removeCookie(`accessToken`)
  window.location.href = '/logout'
}
