import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { isEmpty } from 'lodash'
import Highlighter from 'react-highlight-words'
import { requestGet } from 'utils/request'
import './styles.scss'

class Search extends Component {
  reload_timer
  state = {
    search: '',
    data: [],
    focusIndex: 0,
    show: false,
  }

  componentDidMount() {
    window.addEventListener('keydown', this.handleKeyPress)
    window.addEventListener('click', this.handleClick)
  }

  componentWillUnmount() {
    clearTimeout(this.reload_timer)
    window.removeEventListener('keydown', this.handleKeyPress)
    window.removeEventListener('click', this.handleClick)
  }

  ///////////////////////////////////////
  ///// window events for key press /////
  ///////////////////////////////////////

  handleClick = (e) => {
    if (this.searchBox && this.searchBox.contains(e.target)) {
      this.setState({
        show: true,
      })
      document.body.style.overflow =
        this.state.search.length > 0 ? 'hidden' : ''
    } else if (!e.target.classList.contains('search-clear-group')) {
      this.setState({
        show: false,
      })
      document.body.style.overflow = ''
    }
  }

  handleKeyPress = (e) => {
    if (e.keyCode === 66 && (e.metaKey || e.ctrlKey)) {
      this.showSearch()
    } else if (e.which === 27) {
      this.hideSearch()
    }
    const { search, data, focusIndex, show } = this.state
    if (search) {
      if (e.keyCode === 40 && focusIndex < data.length - 1) {
        this.setState(
          {
            focusIndex: focusIndex + 1,
          },
          () => this.adjustScrollPosition(true)
        )
      }
      if (e.keyCode === 38 && focusIndex > 0) {
        this.setState(
          {
            focusIndex: focusIndex - 1,
          },
          () => this.adjustScrollPosition(false)
        )
      }
      if (e.keyCode === 13 && focusIndex !== -1 && show) {
        if (
          Object.values(data) &&
          Object.values(data)[0] &&
          Object.values(data)[0][0]
        ) {
          const firstItem = Object.values(data)[0][0]
          this.selectItem(firstItem)
          this.hideSearch()
        }
      }
      if (e.keyCode === 9) {
        this.clearSearch()
      }
    }
  }

  showSearch = () => {
    this.searchInput.focus()
    this.setState(
      {
        show: true,
      },
      () =>
        (document.body.style.overflow =
          this.state.search.length > 0 ? 'hidden' : '')
    )
  }

  hideSearch = () => {
    this.searchInput.blur()
    this.setState(
      {
        show: false,
      },
      () => (document.body.style.overflow = '')
    )
  }

  adjustScrollPosition(forward) {
    const { focusIndex, carrierStartIndex } = this.state
    let scrollOffset = this.optionsWrap.scrollTop
    let carrierScroll = focusIndex >= carrierStartIndex ? 84 : 0
    if (forward) {
      scrollOffset = (focusIndex + 1) * 57 - 240 + carrierScroll
    } else {
      scrollOffset =
        (focusIndex - 1) * 57 < scrollOffset
          ? (focusIndex - 1) * 57
          : scrollOffset
    }
    this.optionsWrap.scrollTop = scrollOffset
  }

  handleSearch(e) {
    const { value } = e.target
    clearTimeout(this.reload_timer)
    this.reload_timer = setTimeout(() => {
      this.setState(
        {
          search: value,
        },
        () => {
          this.loadData()
          if (this.state.search.length > 0)
            document.body.style.overflow = 'hidden'
          else document.body.style.overflow = ''
        }
      )
    }, 300)
  }

  clearSearch(clearInput = false) {
    this.setState({
      search: '',
      focusIndex: 0,
      show: clearInput,
    })
    if (clearInput) {
      this.searchInput.value = ''
      this.searchInput.focus()
    }
  }

  loadData() {
    this.setState(
      {
        loading: true,
      },
      () => {
        const { search } = this.state
        requestGet(`index?name=${search}`)
          .then((response) => {
            console.log(response)
            let data = response.data.reduce((acc, curr) => {
              acc[curr.type] = acc[curr.type] || []
              acc[curr.type].push(curr)
              return acc
            }, {})
            this.setState({
              data,
              focusIndex: 0,
              loading: false,
            })
          })
          .catch((err) => {
            this.setState({
              loading: false,
            })
          })
      }
    )
  }

  selectItem(item) {
    this.setState({ show: false })
    let url
    if (item.type === 'carriers') {
      url = `/voice/carriers/${item.id}`
    } else if (item.type === 'vendors') {
      url = `/vendor/${item.id}/home`
    } else {
      url = `/accounts/${item.id}/home`
    }
    window.location.href = url
  }

  render() {
    //const { isSearchCached } = this.props
    const { search, data, focusIndex, show, loading } = this.state
    return (
      <>
        <div
          className={`search-box flex flex-full`}
          ref={(ref) => (this.searchBox = ref)}
        >
          <div
            className={`search-input ${show && search.length > 0 && 'show'}`}
          >
            {search.length > 0 ? (
              <span
                className="search-clear search-clear-group"
                onClick={() => this.clearSearch(true)}
              >
                <span className="clear-icon search-clear-group">
                  <span className="search-clear-group">×</span>
                </span>
              </span>
            ) : (
              <span className="search-icon icon-search" />
            )}
            <input
              ref={(ref) => (this.searchInput = ref)}
              maxLength={20}
              type="text"
              placeholder="Search Company, Account or Vendor"
              onChange={(e) => this.handleSearch(e)}
            />
            {/* <ReactCSSTransitionGroup
							transitionName={'search-transition'}
							transitionEnterTimeout={500}
							transitionLeaveTimeout={500}
            > */}
            {search.length > 0 && show && (
              <div
                className={`search-result`}
                ref={(wrap) => {
                  this.optionsWrap = wrap
                }}
              >
                <div className="search-result-list">
                  {Object.keys(data).map((key) => {
                    return (
                      <>
                        <div className="search-result-type">{key}</div>
                        {data[key].map((item, i) => {
                          return (
                            <>
                              <p key={item.id}>
                                <span
                                  className={`tlink ${
                                    focusIndex === i && 'active'
                                  }`}
                                  onClick={(e) => this.selectItem(item)}
                                >
                                  <Highlighter
                                    className="highlighter"
                                    unhighlightClassName="unhighlighted"
                                    highlightClassName="highlighted"
                                    searchWords={[search]}
                                    autoEscape={true}
                                    textToHighlight={`${item.name}`}
                                  />
                                  &nbsp;
                                  {item.status !== 'Active' && (
                                    <span className="search-result-status">
                                      {item.status}
                                    </span>
                                  )}
                                  <small>{item.id}</small>
                                </span>
                              </p>
                            </>
                          )
                        })}
                      </>
                    )
                  })}
                  {(!data || (data && isEmpty(data))) && (
                    <p className="empty">
                      {loading ? (
                        <>Loading...</>
                      ) : (
                        <>
                          Nothing turned up. You may want to try using different
                          keywords
                        </>
                      )}
                    </p>
                  )}
                </div>
              </div>
            )}
            {/* </ReactCSSTransitionGroup> */}
          </div>
        </div>
      </>
    )
  }
}

export default withRouter(Search)
