export const API_BASE = `${
  process.env.REACT_APP_HUB_BASE_URL || 'https://api.ss.dev'
}/hub/v2`

export const APIS = {
  LOGIN: '/login',
  DATASETS: '/index/form',
  DESTINATIONS: '/destinations/dial-codes',
  DESTINATIONS_FROM: '/destinations',
}
