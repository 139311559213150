import React from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'

import { Input } from 'samespace-ui-kit'
import SearchBar from 'components/SearchBar'

import './styles.scss'

export function HeaderBar({ profileMenu, onMenuToggle }) {
  const history = useHistory()
  return (
    <header className="header">
      <section className="header__wrapper">
        <section className="header__section logo">
          <span>
            <a className="header-logo" href="/">
              <img
                src={require('../../assets/icons/newIcons/Hub.svg')}
                alt="Hub|Samespace"
              />
            </a>
          </span>
        </section>
        <section
          className="header__section"
          style={{
            margin: 0,
          }}
        >
          <article className="nav-arrows">
            <span className="nav-arrow prev" onClick={() => history.goBack()}>
              <span className="kit-icon-arrow-left" />
            </span>
            <span
              className="nav-arrow next"
              onClick={() => history.goForward()}
            >
              <span className="kit-icon-arrow-right" />
            </span>
          </article>
        </section>
        <section className="header__section stretch">
          <article className="header-search">
            <div className="header-search__icon">
              <span className="kit-icon-search"></span>
            </div>
            <div className="header-search__text">
              <SearchBar />
            </div>
          </article>
        </section>
        <section className="header__section">
          <span>
            <a className="header-link" href="/manage/teams">
              <span className="kit-icon-bell" />
            </a>
          </span>
          {profileMenu && <span>{profileMenu}</span>}
        </section>
      </section>
    </header>
  )
}

export function SubHeaderBar({ children }) {
  return <section className="subheader">{children}</section>
}

HeaderBar.propTypes = {
  /** Component for Profile */
  profileMenu: PropTypes.node,
  /** Callback function on hamburger click */
  onMenuToggle: PropTypes.func,
}
