import React from 'react'
import { Switch, Route } from 'react-router-dom'
import DefaultOutbound from './DefaultOutbound'
import DefaultLocal from './DefaultLocal'
import DefaultTollFree from './DefaultTollFree'

function Default() {
  return (
    <Switch>
      <Route exact path="/voip/default/outbound/:id/:currency">
        <DefaultOutbound />
      </Route>
      <Route exact path="/voip/default/local/:id/:currency">
        <DefaultLocal />
      </Route>
      <Route exact path="/voip/default/tollfree/:id/:currency">
        <DefaultTollFree />
      </Route>
    </Switch>
  )
}

export default Default
