import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { withRouter, NavLink } from 'react-router-dom'
import { Tooltip, ScrollBox, Input } from 'samespace-ui-kit'
import './styles.scss'
class Navigation extends Component {
  scrollEl = null
  updateItemVisibility = () => {
    if (this.scrollEl) {
      const link = document.querySelector('.nav-link.active')
      if (link) {
        const elem = link.closest('.nav-section')
        const area = this.scrollEl.area
        const areaBounds = area.getBoundingClientRect()
        const elemBounds = elem.getBoundingClientRect()
        const diff = elemBounds.top - areaBounds.top
        area.scrollTop = diff
      }
    }
  }
  componentDidMount() {
    this.updateItemVisibility()
  }
  render() {
    const { minimized, items, profileMenu, headerImg, searchBar } = this.props
    return (
      <nav className={classnames('navbar', { minimized: minimized })}>
        <ScrollBox ref={(ref) => (this.scrollEl = ref)}>
          <div className="navbar__wrapper">
            {items.map((section) => {
              return (
                <>
                  {section.pages.filter((item) => item.show).length > 0 && (
                    <section
                      key={`nav-section-${section.key}-${section.label}`}
                      className="nav-section"
                    >
                      {section.label && <label>{section.label}</label>}
                      <article>
                        {section.pages
                          .filter((item) => item.show)
                          .map((page, i) => {
                            return (
                              <NavLink
                                key={`nav-link-${page.label}`}
                                to={page.link}
                                className="nav-link"
                              >
                                {minimized && (
                                  <Tooltip
                                    position="right"
                                    className="nav-link__tooltip"
                                    title={minimized ? page.label : undefined}
                                    delay={1000}
                                  />
                                )}
                                {page.icon && (
                                  <span className="nav-link__icon">
                                    <img src={page.icon} alt={page.label} />
                                  </span>
                                )}
                                <span className="nav-link__label">
                                  {page.label}
                                </span>
                              </NavLink>
                            )
                          })}
                      </article>
                    </section>
                  )}
                </>
              )
            })}
          </div>
        </ScrollBox>
      </nav>
    )
  }
}
export const NavigationBar = withRouter(Navigation)
NavigationBar.propTypes = {
  /** sections & pages in array format */
  items: PropTypes.array,
  /** show navbar in minimized state */
  minimized: PropTypes.bool,
}
NavigationBar.defaultProps = {
  items: [],
  minimized: false,
}
