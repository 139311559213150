import React, { Component } from 'react'
import { CSSTransition, TransitionGroup } from 'react-transition-group'

import './style.scss'

const WIZARD_ANIMATION_DURATION = 500

export class Transition extends Component {
  constructor(props) {
    super(props)
    this.state = props.noInitHeight
      ? {}
      : {
          height: 0,
        }
  }

  static getDerivedStateFromProps(props, state) {
    return {
      height: props.children ? state.height : 0,
    }
  }

  render() {
    const {
      children,
      style: propStyle,
      activeClassName,
      inactiveClassName,
      className,
    } = this.props
    const { height } = this.state
    let style = {
      height,
    }
    if (children && propStyle) {
      style = {
        ...style,
        ...this.props.style,
      }
    }
    return (
      <TransitionGroup
        className={`mount-transition ${className} ${
          children ? activeClassName : inactiveClassName
        }`}
        style={style}
      >
        <CSSTransition
          classNames="mount-transition"
          timeout={WIZARD_ANIMATION_DURATION}
        >
          <>
            {children && (
              <div
                className="mount-transition-wrap"
                ref={ref => {
                  ref &&
                    ref.getBoundingClientRect() &&
                    ref.getBoundingClientRect().height !== height &&
                    this.setState({
                      height: ref.getBoundingClientRect().height,
                    })
                }}
              >
                {children}
              </div>
            )}
          </>
        </CSSTransition>
      </TransitionGroup>
    )
  }
}
