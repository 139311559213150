import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Textarea from 'react-textarea-autosize'
import TimeField from 'react-simple-timefield'

import { Label } from 'samespace-ui-kit'

import './styles.scss'

// TODO : Time Input

class Input extends Component {
  constructor() {
    super()
    this.state = {
      passwordType: null,
    }
  }

  focus() {
    this.textInput.focus()
  }

  blur() {
    this.textInput.blur()
  }

  onMouseEnter() {
    this.setState({ passwordType: 'text' })
  }

  onMouseLeave() {
    this.setState({ passwordType: 'password' })
  }

  render() {
    const {
      type,
      min = 0,
      max = Number.MAX_SAFE_INTEGER,
      step,
      size = 'regular',
      icon,
      iconPosition,
      label,
      helpLabel,
      name,
      placeholder,
      maxlength,
      value,
      disabled,
      togglePassword,
      error,
      errorText,
      description,
      autoSize,
      autoFocus,
      textarea,
      time,
      readOnly,
    } = this.props
    const { passwordType } = this.state

    const id = `text-${name}`
    const InputComponent = textarea
      ? autoSize
        ? Textarea
        : 'textarea'
      : time
      ? TimeField
      : 'input'

    return (
      <div
        className={classNames(
          `input-box`,
          `input-box--${size}`,
          { 'input-box--time': time },
          { 'input-box--has-error': error },
          { 'input-box--validable': errorText },
          { 'input-box--with-label': label },
          { 'input-box--with-icon': icon },
          { 'input-box--with-icon--left': iconPosition === 'left' },
          { 'input-box--with-icon--right': iconPosition === 'right' }
        )}
      >
        <InputComponent
          showSeconds
          noValidate
          id={id}
          name={name}
          type={passwordType || type || 'text'}
          placeholder={placeholder || ''}
          min={min !== undefined ? min : ''}
          max={max !== undefined ? max : ''}
          step={step}
          value={value}
          disabled={disabled}
          readOnly={readOnly}
          autoFocus={autoFocus}
          autoComplete="new-password"
          ref={(input) => {
            this.textInput = input
          }}
          onChange={(e) => {
            if (disabled || readOnly) return
            if (type !== 'time' && !textarea && e.target.value !== '') {
              if (type === 'password') {
                e.target.value = (e.target.value || '').trim('')
              } else {
                e.target.value = e.target.value.replace(/ +/g, ' ')
              }
            }
            this.props.onChange && this.props.onChange(e)
            if (this.props.error && this.props.onBlur) {
              setTimeout(() => {
                this.props.onBlur(e)
              }, 100)
            }
          }}
          onBlur={(e) => {
            if (type === 'number') {
              if (min && e.target.value < parseFloat(min, 10)) {
                e.target.value = min
                this.props.onChange && this.props.onChange(e)
              }
              if (max && e.target.value > parseFloat(max, 10)) {
                e.target.value = max
                this.props.onChange && this.props.onChange(e)
              }
            }
            if (type !== 'number') {
              e.target.value = (e.target.value || '')
                .trim()
                .replace(/\s\s+/g, ' ')
              if (e.target.value !== value)
                this.props.onChange && this.props.onChange(e)
            }
            this.props.onBlur && this.props.onBlur(e)
          }}
          onWheel={(e) => e.target.blur()}
          onFocus={(e) => this.props.onFocus && this.props.onFocus(e)}
          onKeyUp={(e) => this.props.onKeyUp && this.props.onKeyUp(e)}
          onMouseEnter={(e) => togglePassword && this.onMouseEnter()}
          onMouseLeave={(e) => togglePassword && this.onMouseLeave()}
        />
        {maxlength && (
          <span className="input-counter">
            {value ? (
              maxlength >= value.length ? (
                <span>
                  {maxlength - value.length} character
                  {maxlength - value.length > 1 ? 's' : ''} remaining
                </span>
              ) : (
                <span className="text--red">
                  too long by {value.length - maxlength} character
                  {value.length - maxlength > 1 ? 's' : ''}
                </span>
              )
            ) : (
              `${maxlength} characters remaining`
            )}
          </span>
        )}
        {label && (
          <Label htmlFor={id} title={label} description={description} />
        )}
        {helpLabel && <label className="input-helper">{helpLabel}</label>}
        {error && errorText && (
          <span className="input-note error">
            <span className="input-note__icon-error">!</span> {errorText}
          </span>
        )}
        {icon && (
          <i
            className={`icon kit-icon-${icon}`}
            onClick={(e) => this.props.onIconClick && this.props.onIconClick(e)}
          />
        )}
      </div>
    )
  }
}

Input.propTypes = {
  /** input type */
  type: PropTypes.string,
  /** minimum limit for number input */
  min: PropTypes.number,
  /** max limit for number input */
  max: PropTypes.number,
  /** icon class name */
  icon: PropTypes.string,
  /** icon position class name */
  iconPosition: PropTypes.string,
  /** label for input */
  label: PropTypes.string,
  /** add a help text */
  helpLabel: PropTypes.string,
  /** size of input */
  size: PropTypes.string,
  /** form name for input */
  name: PropTypes.string,
  /** placeholder text */
  placeholder: PropTypes.string,
  /** max length of characters allowed */
  maxlength: PropTypes.number,
  /** input value */
  value: PropTypes.string,
  /** disable input */
  disabled: PropTypes.bool,
  /** show password text on focus */
  togglePassword: PropTypes.bool,
  /** show error text */
  error: PropTypes.bool,
  /** error text to display */
  errorText: PropTypes.string,
  /** Label text */
  description: PropTypes.string,
  /**  render as textarea */
  textarea: PropTypes.bool,
  /**  render as time input */
  time: PropTypes.bool,
  /** automatically increases size on change */
  autoSize: PropTypes.bool,
  /** automatically focus on render */
  autoFocus: PropTypes.bool,
  /** onChange event */
  onChange: PropTypes.func.isRequired,
  /** onBlur event */
  onBlur: PropTypes.func,
  /** onFocus event */
  onFocus: PropTypes.func,
  /** onKeyUp event */
  onKeyUp: PropTypes.func,
  /** onIconClick event */
  onIconClick: PropTypes.func,
}

export { Input }
