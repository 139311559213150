import React from 'react'
import ReactDOM from 'react-dom'

import App from 'App'
import * as serviceWorker from 'serviceWorker'

import { SessionProvider } from 'context/session'
import { DatasetProvider } from 'context/datasets'

function Samespace() {
  return (
    <SessionProvider>
      <DatasetProvider>
        <App />
      </DatasetProvider>
    </SessionProvider>
  )
}

ReactDOM.render(
  <React.StrictMode>
    <Samespace />
  </React.StrictMode>,
  document.getElementById('root')
)
serviceWorker.unregister()
