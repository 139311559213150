import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router'
import axios from 'axios'
import {
  TableCell,
  TableBody,
  TableRow,
  Table,
  TableHead,
  Flag,
} from 'samespace-ui-kit'
function DefaultOutbound() {
  const params = useParams()
  const [pricing, setPricing] = useState('')
  useEffect(() => {
    axios
      .get(
        `${
          process.env.REACT_APP_HUB_BASE_URL || 'https://api.ss.dev'
        }/hub/v2/customers/voice/setup/${params.id}/outbound/default`
      )
      .then(({ data }) => {
        setPricing(data.data)
      })
  }, [])
  return (
    <>
      <div className="flow-page-nav">
        <img
          src={require('../../assets/img/onboarding/nav-header.svg')}
          alt="SameSpace | Logo"
        />
        <div
          className="nav-help"
          onClick={() => window.open('https://support.samespace.com/')}
        >
          Need Help?
        </div>
      </div>
      <div className="voip-terms-container">
        <div className="voip-terms-width">
          <div className="setup-voip">View Default Pricing</div>
          <div className="voip-terms-head">Review our products & pricing</div>
          <div className="voip-terms-desc">
            If you have any question about the pricing, Please contact your
            Samespace representative.
          </div>
          {Array.isArray(pricing) && pricing.length > 0 && (
            <>
              <div className="voip-table-header">Default Outbound Pricing</div>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Country</TableCell>
                    <TableCell>Rate/Min</TableCell>
                    <TableCell>Connection Charge</TableCell>
                    <TableCell>Incrementals</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {pricing.map((item) => {
                    return (
                      <TableRow>
                        <TableCell>
                          <div className="table-with-flag">
                            <Flag size="m" code={item.iso_code} />
                            <div className="field">{item.destination}</div>
                          </div>
                        </TableCell>
                        <TableCell>
                          {Number(params.currency) === 1 ? '$' : '₹'}
                          {Number(item.rate_per_min).toFixed(2)}
                        </TableCell>
                        <TableCell>
                          {Number(params.currency) === 1 ? '$' : '₹'}{' '}
                          {Number(item.connection_charge).toFixed(2)}
                        </TableCell>
                        <TableCell>
                          {item.increment}/{item.interval}
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default DefaultOutbound
