import React, { useState, useEffect } from 'react'
import { Router, Switch, Route, Redirect } from 'react-router-dom'
import {
  App as AppMaster,
  AppWrapper,
  Loader,
  SkeletonTheme,
} from 'samespace-ui-kit'
import SSOLogin from 'components/SSOLogin'
import { ErrorBoundry, Navigation, LazyLoader, Header } from 'components'
import { useSession } from 'context/session'
import { useDatasets } from 'context/datasets'
import { fetchDatasets } from 'utils/datasets'
import { getSavedSession } from 'utils/session'
import { setAxiosDefaults } from 'utils/request'

import 'samespace-ui-kit/static/styles.css'
import 'assets/icons/style.css'
import 'assets/styles.scss'
import Default from 'pages/Default'

const Login = React.lazy(() =>
  import(/* webpackChunkName: 'login' */ 'pages/Login')
)
const SignUp = React.lazy(() =>
  import(/* webpackChunkName: 'signup' */ 'pages/SignUp')
)
const NewSpace = React.lazy(() =>
  import(/* webpackChunkName: 'newspace' */ 'pages/NewSpace')
)
const NewVoIP = React.lazy(() =>
  import(/* webpackChunkName: 'newspace' */ 'pages/NewVoIP')
)
const Onboarding = React.lazy(() =>
  import(/* webpackChunkName: 'onboarding' */ 'pages/Onboarding')
)
const Home = React.lazy(() =>
  import(/* webpackChunkName: 'home' */ 'pages/Home')
)
const Analytics = React.lazy(() =>
  import(/* webpackChunkName: 'analytics' */ 'pages/Analytics')
)
const Accounts = React.lazy(() =>
  import(/* webpackChunkName: 'accounts' */ 'pages/Accounts')
)
const Settings = React.lazy(() =>
  import(/* webpackChunkName: 'settings' */ 'pages/Settings')
)
const Voice = React.lazy(() =>
  import(/* webpackChunkName: 'voice' */ 'pages/Voice')
)
const Destinations = React.lazy(() =>
  import(/* webpackChunkName: 'destinations' */ 'pages/Destinations')
)
const Space = React.lazy(() =>
  import(/* webpackChunkName: 'space' */ 'pages/Space')
)
const SpacePricing = React.lazy(() =>
  import(/* webpackChunkName: 'space-pricing' */ 'pages/SpacePricing')
)
const Accounting = React.lazy(() =>
  import(/* webpackChunkName: 'accounting' */ 'pages/Accounting')
)
const Vendors = React.lazy(() =>
  import(/* webpackChunkName: 'vendors' */ 'pages/Vendors')
)

const Sales = React.lazy(() =>
  import(/* webpackChunkName: 'sales' */ 'pages/Settings/Sales')
)
const ResetPassword = React.lazy(() =>
  import(/* webpackChunkName: 'resetpassword' */ 'pages/ResetPassword')
)

const ResetPasswordConfirmation = React.lazy(() =>
  import(
    /* webpackChunkName: 'resetpasswordconfirmation' */ 'pages/ResetPasswordConfirmation'
  )
)

function Internal({ history }) {
  const [, setDatasets] = useDatasets()
  const [loading, setLoading] = useState(true)
  const [minimize, setMinimize] = useState(false)

  useEffect(() => {
    async function requestDatasets() {
      const data = await fetchDatasets()
      if (data) {
        setDatasets(data)
        setLoading(false)
      }
      setLoading(false)
    }
    requestDatasets()
  }, [setDatasets])

  return (
    <>
      {loading && <Loader />}
      {!loading && (
        <div className="app-container-new">
          <Header onMenuToggle={() => setMinimize(!minimize)} />
          <Navigation minimized={minimize} />
          <AppWrapper>
            <ErrorBoundry>
              <React.Suspense fallback={<LazyLoader />}>
                <Switch>
                  <Route exact path="/home" render={() => <Home />} />
                  <Route
                    exact
                    path="/vendor/:id?/:section?/:subsection?"
                    render={() => <Vendors />}
                  />
                  <Route
                    exact
                    path="/accounts/:id?/:section?/:subsection?"
                    render={() => <Accounts />}
                  />
                  <Route
                    exact
                    path="/analytics/:section?"
                    render={() => <Analytics />}
                  />
                  <Route exact path="/sales" render={() => <Sales />} />
                  <Route
                    exact
                    path="/settings/:section?/:action?/:id?"
                    render={() => <Settings />}
                  />
                  <Route
                    exact
                    path="/account/:section?/:sub?/:type?"
                    render={() => <Accounting />}
                  />
                  <Route
                    exact
                    path="/voice/destinations"
                    render={() => <Destinations />}
                  />
                  <Route
                    path="/voice/:section/:Id?/:section?"
                    render={() => <Voice />}
                  />
                  <Route
                    exact
                    path="/space/pricing/:companyId?/:planId?"
                    render={() => <SpacePricing />}
                  />
                  <Route path="/space/:section/:id?" render={() => <Space />} />
                  <Route exact path="/new_space/create">
                    <NewSpace />
                  </Route>
                  <Route exact path="/new_voip/create">
                    <NewVoIP />
                  </Route>
                  <Route exact path="/onboarding">
                    <Onboarding />
                  </Route>
                  <Route>
                    <Redirect to="/accounts" />
                  </Route>
                </Switch>
              </React.Suspense>
            </ErrorBoundry>
          </AppWrapper>
        </div>
      )}
    </>
  )
}

function App() {
  const [session, setSession] = useSession()
  useEffect(() => {
    async function checkSession() {
      const savedSession = getSavedSession()
      if (savedSession) {
        setAxiosDefaults({
          hubToken: savedSession.accessToken,
        })
        setSession({
          loaded: true,
          loggedIn: true,
          hubToken: savedSession.accessToken,
          user: savedSession.userInfo,
        })
      } else {
        setSession({
          loaded: true,
        })
      }
    }
    checkSession()
  }, [setSession])

  return (
    <div className="app">
      <SkeletonTheme>
        <AppMaster basename="/">
          {({ history }) => {
            return (
              <>
                <Router history={history}>
                  <React.Suspense fallback={<Loader />}>
                    {session.loaded && (
                      <>
                        {session.loggedIn ? (
                          <Switch>
                            <Route>
                              <Internal history={history} />
                            </Route>
                          </Switch>
                        ) : (
                          <Switch>
                            <Route exact path="/join/user/:id">
                              <SignUp />
                            </Route>
                            <Route exact path="/reset-password">
                              <ResetPassword />
                            </Route>
                            <Route exact path="/reset/user/:id">
                              <ResetPasswordConfirmation />
                            </Route>
                            <Route exact path="/sso/authorize">
                              <SSOLogin />
                            </Route>
                            <Route exact path="/login">
                              <Login ssoRedirect={true} />
                            </Route>
                            <Route
                              exact
                              path="/voip/default/:section/:id/:currency"
                            >
                              <Default />
                            </Route>
                            <Route exact path="/new_space/create">
                              <NewSpace />
                            </Route>
                            <Route exact path="/new_voip/create">
                              <NewVoIP />
                            </Route>
                            <Route exact path="/onboarding">
                              <Onboarding />
                            </Route>
                            <Route exact path="/logout" ssoRedirect={false}>
                              <Login />
                            </Route>
                            <Route>
                              <Redirect to="/login" />
                            </Route>
                          </Switch>
                        )}
                      </>
                    )}
                  </React.Suspense>
                </Router>
              </>
            )
          }}
        </AppMaster>
      </SkeletonTheme>
    </div>
  )
}

export default App
