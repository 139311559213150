import React from 'react'
import { SlideDown as ReactSlideDown } from 'react-slidedown'

import 'react-slidedown/lib/slidedown.css'
import './style.scss'

export function SlideDown({ className, children }) {
  return (
    <article className={`slidebox ${className}`}>
      <ReactSlideDown>{children}</ReactSlideDown>
    </article>
  )
}
