import React, { createContext, useState, useContext, useMemo } from 'react'

export const initialSession = {
  loaded: false,
  loggedIn: false,
  hubToken: null,
  user: null,
}

const SessionContext = createContext()

export function useSession() {
  const context = useContext(SessionContext)
  if (!context) {
    throw new Error(`useSession must be used within a SessionProvider`)
  }
  return context
}

export function SessionProvider(props) {
  const [session, setSession] = useState(props.value || initialSession)
  const value = useMemo(() => [session, setSession], [session])
  return <SessionContext.Provider value={value} {...props} />
}
