import React, { Component } from 'react'
import copy from 'copy-to-clipboard'

import './styles.scss'

export class Copier extends Component {
  constructor() {
    super()
    this.state = {
      copied: false,
    }
  }

  performCopy() {
    const { text } = this.props
    const { copied } = this.state
    copy(text)
    if (!copied) {
      this.setState(
        {
          copied: true,
        },
        () => {
          setTimeout(() => {
            this.setState({
              copied: false,
            })
          }, 1500)
        }
      )
    }
  }

  render() {
    const {
      text,
      secureMode,
      hideText = false,
      withScroll,
      persist,
      copierText = 'Copy',
      onClick,
      className,
    } = this.props
    const { copied } = this.state
    return (
      <div
        className={`copier ${className} ${copied && 'copied'} ${
          secureMode && 'secure'
        } ${persist && 'persist'}`}
        onClick={(e) => {
          e.stopPropagation()
          if (onClick) {
            onClick()
            return
          }

          this.performCopy()
        }}
      >
        {secureMode && <span className="copier__dots">●●●●●●●●●●●●●●●</span>}
        {!hideText && (
          <span className={`copier__text ${withScroll && 'withScroll'}`}>
            {text}
          </span>
        )}
        <span className="copier__action">{copierText}</span>
      </div>
    )
  }
}
