import React, { Component } from 'react'
import { motion } from 'framer-motion'
import './style.scss'

export class SideDrawer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      scrolled: false,
    }
  }
  componentDidMount() {
    if (document.querySelector('.form-padding')) {
      document
        .querySelector('.form-padding')
        .addEventListener('scroll', (e) => {
          if (e.target.scrollTop > 0) {
            this.setState({
              scrolled: true,
            })
          } else {
            this.setState({
              scrolled: false,
            })
          }
        })
    }

    document.addEventListener('keydown', this.handleEscButton)
    this.disableScroll()
  }

  componentWillUnmount() {
    this.enableScroll()
    if (document.querySelector('.form-padding')) {
      document
        .querySelector('.form-padding')
        .removeEventListener('scroll', () => (e) => {
          if (e.target.scrollTop > 0) {
            this.setState({
              scrolled: true,
            })
          } else {
            this.setState({
              scrolled: false,
            })
          }
        })
    }
    document.removeEventListener('keydown', this.handleEscButton)
  }

  handleEscButton = async (e) => {
    if (e.key === 'Escape' || e.key === 'Esc' || e.keyCode === 27) {
      e.preventDefault()
      this.props.onClose()
    }
  }

  disableScroll = function () {
    let html = document.documentElement
    let body = document.body
    var initWidth = body.offsetWidth
    var initHeight = body.offsetWidth

    var scrollPosition = [
      window.pageXOffset ||
        document.documentElement.scrollLeft ||
        document.body.scrollLeft,
      window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop,
    ]

    html.dataset.scrollPosition = scrollPosition
    html.dataset.previousOverflow = html.style.overflow
    html.style.overflow = 'hidden'

    window.scrollTo(scrollPosition[0], scrollPosition[1])

    var marginR = body.offsetWidth - initWidth
    var marginB = body.offsetWidth - initHeight
    body.style.marginRight = marginR
    body.style.marginBottom = marginB
  }

  enableScroll() {
    let html = document.documentElement
    let body = document.body

    html.style.overflow = ''
    let scrollPosition = html.dataset.scrollPosition
    if (scrollPosition) {
      scrollPosition = scrollPosition.split(',')
      window.scrollTo(scrollPosition[0], scrollPosition[1])
      setTimeout(() => {
        html.dataset.scrollPosition = null
      }, 450)
    }

    body.style.marginRight = 0
    body.style.marginBottom = 0
  }

  render() {
    const { children, onClose, width } = this.props

    return (
      <div className="side-drawer-container">
        <motion.div
          position="layout"
          initial={{
            x: '500px',
            opacity: 0,
          }}
          animate={{
            x: '0px',
            opacity: 1,
          }}
          transition={{
            duration: 0.4,
            ease: [0.7, 0, 0.05, 1],
          }}
          exit={{
            x: '500px',
            opacity: 0,
          }}
          id="side-drawer-wrapper"
          style={{
            width: width,
          }}
          className="side-drawer-wrapper"
        >
          <span
            className={`close-position ${
              this.state.scrolled ? 'close-position-with-shadow' : ''
            }`}
          >
            <div
              className="close-position-btn"
              onClick={() => {
                onClose()
              }}
            >
              <span className="kit-icon-close" />
            </div>
          </span>
          <div
            className="close-position-btn"
            onClick={() => {
              onClose()
            }}
          >
            <span className="kit-icon-close" />
          </div>
          {children}
        </motion.div>
      </div>
    )
  }
}
