import React from 'react'
import { NavigationBar } from 'components/NavigationBar'
import { getPrivileges } from 'utils/session'

const privileges = getPrivileges()
const ANALYTICS_SECTIONS = [
  {
    key: 'heading',
    pages: [
      {
        icon: require('../../assets/icons/newIcons/back.svg'),
        label: 'Analytics',
        link: '/',
        active: true,
        show: true,
      },
    ],
  },
  {
    key: 'call-logs',
    pages: [
      {
        label: 'Call Logs',
        link: '/analytics/call-logs',
        show: privileges?.reports?.call_logs >= 1,
      },
    ],
  },
  {
    key: 'books',
    label: 'Books',
    pages: [
      {
        label: 'Balance Sheet',
        link: '/analytics/balance-sheet',
        show: privileges?.reports?.books >= 1,
      },
      {
        label: 'GSTR-1',
        link: '/analytics/gstr1',
        show: privileges?.reports?.books >= 1,
      },
      {
        label: 'Tax Withheld Payable',
        link: '/analytics/twp',
        show: privileges?.reports?.books >= 1,
      },
      {
        label: 'Tax Withheld Receivable',
        link: '/analytics/twr',
        show: privileges?.reports?.books >= 1,
      },
      {
        label: 'Account Summary',
        link: '/analytics/books-usage',
        show: privileges?.reports?.books >= 1,
      },
      {
        label: 'Profit/Loss',
        link: '/analytics/profit-loss',
        show: privileges?.reports?.books >= 1,
      }
    ],
  },
  {
    key: 'carrier-statistics',
    label: 'Carrier',
    pages: [
      {
        label: 'Usage Summary',
        link: '/analytics/carrier-usage-summary',
        show: privileges?.reports?.carrier_statistics >= 1,
      },
      {
        label: 'Rates',
        link: '/analytics/carrier-rates',
        show: privileges?.reports?.carrier_statistics >= 1,
      },
      {
        label: 'Success Ratio',
        link: '/analytics/carrier-success-ratio',
        show: privileges?.reports?.carrier_statistics >= 1,
      },
      {
        label: ' Short Duration',
        link: '/analytics/carrier-short-duration',
        show: privileges?.reports?.carrier_statistics >= 1,
      },
      {
        label: 'CDR Download',
        link: '/analytics/carrier-cdr-download',
        show: privileges?.reports?.carrier_statistics >= 1,
      },
    ],
  },
  {
    key: 'customer-statistics',
    label: 'Customer',
    pages: [
      {
        label: 'Invoices',
        link: '/analytics/customer-invoices',
        show: privileges?.reports?.customer_statistics,
      },
      {
        label: 'Transactions',
        link: '/analytics/customer-transactions',
        show: privileges?.reports?.customer_transactions >= 1,
      },
      {
        label: 'Balance Dues',
        link: '/analytics/customer-balance-dues',
        show: privileges?.reports?.customer_statistics,
      },
      {
        label: 'Revenue',
        link: '/analytics/customer-revenue',
        show: privileges?.reports?.customer_statistics,
      },
      {
        label: 'Success Ratio',
        link: '/analytics/customer-success-ratio',
        show: privileges?.reports?.customer_statistics,
      },
      {
        label: 'Short Duration',
        link: '/analytics/customer-short-duration',
        show: privileges?.reports?.customer_statistics,
      },
      {
        label: 'Usage Summary',
        link: '/analytics/customer-usage-summary',
        show: privileges?.reports?.customer_statistics,
      },
      {
        label: 'Average Call Duration',
        link: '/analytics/avg-call-duration',
        show: privileges?.reports?.customer_statistics,
      },
      {
        label: 'New Customer',
        link: '/analytics/customer-new-customer',
        show: privileges?.reports?.customer_statistics,
      },
      {
        label: 'CDR Download',
        link: '/analytics/customer-cdr-download',
        show: privileges?.reports?.customer_statistics,
      },
    ],
  },
]

const MENU_SECTIONS = [
  {
    key: 'top',
    pages: [
      {
        icon: require('../../assets/icons/newIcons/analytics.svg'),
        label: 'Analytics',
        link: '/analytics/call-logs',
        show: privileges?.reports?.reports >= 1,
      },
      {
        icon: require('../../assets/icons/newIcons/sales.svg'),
        label: 'Sales',
        link: '/sales',
        show: privileges?.sales?.sales >= 1,
      },
    ],
  },
  {
    key: 'basic',
    label: 'Accounts',
    pages: [
      {
        icon: require('../../assets/icons/newIcons/accounts.svg'),
        label: 'Customers',
        link: '/accounts',
        show: privileges?.customers?.customers >= 1,
      },
      {
        icon: require('../../assets/icons/newIcons/vendors.svg'),
        label: 'Vendors',
        link: '/vendor',
        show: privileges?.vendors?.vendors >= 1,
      },
    ],
  },
  {
    key: 'account',
    label: 'Books',
    pages: [
      {
        icon: require('../../assets/icons/newIcons/payments.svg'),
        label: 'Payment & Receipts',
        link: '/account/payment',
        show:
          privileges?.books?.payments >= 1 || privileges?.books?.receipts >= 1,
      },
      {
        icon: require('../../assets/icons/newIcons/adjustments.svg'),
        label: 'Adjustments',
        link: '/account/adjustments',
        show: privileges?.books?.adjustments >= 1,
      },
      {
        icon: require('../../assets/icons/newIcons/purchases.svg'),
        label: 'Purchases',
        link: '/account/purchases',
        show: privileges?.books?.purchases >= 1,
      },

      {
        icon: require('../../assets/icons/newIcons/expenses.svg'),
        label: 'Expenses',
        link: '/account/expenses',
        show: privileges?.books?.expenses >= 1,
      },
      {
        icon: require('../../assets/icons/newIcons/journals.svg'),
        label: 'Journal',
        link: '/account/journal',
        show: privileges?.books?.journals >= 1,
      },
      {
        icon: require('../../assets/icons/newIcons/po.svg'),
        label: 'PO & PFO',
        link: '/account/orders',
        show:
          privileges?.books?.purchase_order >= 1 ||
          privileges?.books?.proforma_invoice >= 1,
      },
    ],
  },
  {
    key: 'space',
    label: 'Spaces',
    pages: [
      {
        icon: require('../../assets/icons/newIcons/spaces.svg'),
        label: 'Spaces',
        link: '/space/spaces',
        show: privileges?.spaces?.space_cloud >= 1,
      },
      {
        icon: require('../../assets/icons/newIcons/space_pricings.svg'),
        label: 'Pricing',
        link: '/space/pricing',
        show: privileges?.spaces?.pricing >= 1,
      },
    ],
  },
  {
    key: 'voice',
    label: 'VoIP',
    pages: [
      {
        icon: require('../../assets/icons/newIcons/routings.svg'),
        label: 'Routing',
        link: '/voice/routing',
        show: privileges?.voice_routes?.voice_routes >= 1,
      },
      {
        icon: require('../../assets/icons/newIcons/phone_numbers.svg'),
        label: 'Phone Numbers',
        link: '/voice/numbers',
        show: privileges?.phone_numbers?.phone_number >= 1,
      },
      {
        icon: require('../../assets/icons/newIcons/carriers.svg'),
        label: 'Carriers',
        link: '/voice/carriers',
        show: privileges?.carriers?.carriers >= 1,
      },
      {
        icon: require('../../assets/icons/newIcons/voip_pricings.svg'),
        label: 'Pricing',
        link: '/voice/pricing',
        show: privileges?.voip_pricing?.voip_pricing >= 1,
      },
    ],
  },
  {
    key: 'settings',
    pages: [
      {
        icon: require('../../assets/icons/newIcons/settings.svg'),
        label: 'Settings',
        link: '/settings/voip',
        show: true,
      },
    ],
  },
]
const SETTINGS_SECTIONS = [
  {
    key: 'heading',
    pages: [
      {
        icon: require('../../assets/icons/newIcons/back.svg'),
        label: 'Settings',
        link: '/',
        active: true,
        show: true,
      },
    ],
  },
  {
    key: 'products',
    label: 'Products',
    pages: [
      {
        icon: require('../../assets/icons/newIcons/voip.svg'),
        label: 'VoIP',
        link: '/settings/voip/',
        show: privileges?.products?.voip >= 1,
      },
      {
        icon: require('../../assets/icons/newIcons/space.svg'),
        label: 'Spaces',
        link: '/settings/spaces/',
        show: privileges?.products?.spaces >= 1,
      },
      {
        icon: require('../../assets/icons/newIcons/more.svg'),
        label: 'More Products',
        link: '/settings/addons/',
        show: privileges?.products?.more_products >= 1,
      },
    ],
  },
  {
    key: 'organization',
    label: 'Organization',
    pages: [
      {
        icon: require('../../assets/icons/newIcons/companies.svg'),
        label: 'Companies',
        link: '/settings/companies',
        show: privileges?.companies?.companies >= 1,
      },
      {
        icon: require('../../assets/icons/newIcons/currencies.svg'),
        label: 'Currencies',
        link: '/settings/currencies',
        show: privileges?.currencies?.currencies >= 1,
      },
      {
        icon: require('../../assets/icons/newIcons/countries.svg'),
        label: 'Countries',
        link: '/settings/countries',
        show: privileges?.countries?.countries >= 1,
      },
    ],
  },
  {
    key: 'user_management',
    label: 'User Management',
    pages: [
      {
        icon: require('../../assets/icons/newIcons/users.svg'),
        label: 'Users',
        link: '/settings/users',
        show: privileges?.users?.users >= 1,
      },

      {
        icon: require('../../assets/icons/newIcons/sso.svg'),
        label: 'SSO',
        link: '/settings/sso',
        show: privileges?.users?.sso >= 1,
      },
    ],
  },
  {
    key: 'Accounting',
    label: 'Books',
    pages: [
      {
        icon: require('../../assets/icons/newIcons/coa.svg'),
        label: 'Chart of Accounts',
        link: '/settings/chart_accounts',
        show: privileges?.books?.chart_of_accounts >= 1,
      },
    ],
  },
]
function getNavigationItems() {
  if (window.location.href.includes('settings')) {
    return SETTINGS_SECTIONS
  } else if (window.location.href.includes('analytics')) {
    return ANALYTICS_SECTIONS
  } else {
    return MENU_SECTIONS
  }
}
export const Navigation = ({ minimized }) => {
  return (
    <>
      {console.log(privileges)}
      <NavigationBar
        headerImg={
          <img
            src={require('../../assets/img/header-logo.svg')}
            alt="Samespace Logo"
          />
        }
        minimized={minimized}
        items={getNavigationItems()}
      />
    </>
  )
}
